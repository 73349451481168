import React from 'react';
import { withTranslation } from 'react-i18next';
import './Dashboard.scss';
import { AuthContext, AuthSession } from '../../contexts/AuthContext';
import logo_pbb_dark from '../../assets/img/LOGO_PBB_FONCE.svg';
import logo_pbb_light from '../../assets/img/LOGO_PBB_CLAIR2.svg';
import no_favorite_player from '../../assets/img/no_favorite_player.png';
import icon_googleplay from '../../assets/img/google-play.png';
import icon_apple from '../../assets/img/apple-64.png';
import PartnerBloc from '../../components/PartnerBloc/PartnerBloc';
import Title from '../../components/Title/Title';
import { Button, ButtonType, toast, Icon, IconType } from '@om/design-system';
import { getEshop, getOMFR, getTicketing, isWebview } from '../../utils';
import { ConsentName } from '../../models/Consent';
import Shortcut from '../../components/Shortcut/Shortcut';
import APIService from '../../services/APIService';
import { Membership_Type } from '../../models/Membership';
import i18n from "../../i18n";

class Dashboard extends React.Component<any> {
  state = {
    alerts: false as any,
    favorite_player: null as any,
    next_game: null as any,
    om_picture_url: null as any,
    personalized_shirt: null
  }

  getMembershipCard() {
    return new Promise((resolve, reject) => {
      const { token, user } = this.context as AuthSession;

      if (!user?.membership?.id) {
        return;
      }

      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'x-token': token! }
      };

      // add parameter id for varying request and bypassing CDN
      fetch(`/api/wallet?id=${user?.membership?.id}`, options)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch((err: any) => reject(err));
    });
  }

  componentDidMount(): void {
    const { user } = this.context as AuthSession;

    // Get Promo Alerts
    // fetch(`${getOMFR()}/api/1.2/promoevents`)
    //   .then(res => res.json())
    //   .then(data => {
    //     this.setState({ alerts: data })
    //   }).catch((error: any) => {
    //     console.log(error)
    //   });

    if (user && user.favorite_player) {
      APIService.fetchPlayer(user?.favorite_player as any)
        .then((data) => {
          if (data.length === 0) {
            this.setState({ favorite_player: null })
          } else {
            this.setState({ favorite_player: data })
          }
        })
        .catch((error) => {
          console.log(error)
        });
    }

    APIService.fetchNextMatch()
      .then((data) => {
        this.setState({ next_game: data })
      })
      .catch((error) => {
        console.log(error)
      });

    APIService.fetchOMProfile(user?.id as any)
      .then((data) => {
        if (!data.avatar || !data.avatar.urls) return
        this.setState({ om_picture_url: data.avatar.urls.small })
      })
      .catch((error) => {
        console.log(error)
      });

    if (user) {
      APIService.fetchShirt(user.firstName as string, '', 'home')
      .then((data) => {
        this.setState({ personalized_shirt: data })
      })
      .catch((error: any) => {
        console.log(error)
      });
    }
  }

  render() {
    const { t } = this.props;
    const { alerts, favorite_player, next_game, om_picture_url, personalized_shirt } = this.state;
    const { user } = this.context as any;
    const consents = user && user.getConsentsAsDict();
    const atLeastOneActivComm = user && (consents[ConsentName.eshop].granted || consents[ConsentName.fondation].granted || consents[ConsentName.news].granted || consents[ConsentName.partners].granted || consents[ConsentName.ticketing].granted);

    const url = { url: "https://om.fr"} as any
    window.dispatchEvent(new CustomEvent("om-popup-login-action", url))

    if (!user) { return <></> }

    return <>
      {/* Header */}
      <div className='dashboard-header'>
        <div className='background-polygon'>
          <div className='informations'>
            <div className='profile-and-badge'>
              <div className='profile-picture'>
                { om_picture_url ? <>
                  <img className='profile-picture' src={om_picture_url} />
                </> : <>
                <div className='no-picture'><Icon type={IconType.Profile} /></div>
                </>}
              </div>
              {user.membership && <div className='badge'>
                <img src={logo_pbb_light} />
              </div>}
            </div>
            <div className='name'>
              <div className='background'></div>
              <Title>
                <div className='primary'>{user.firstName}</div>
                <div>{user.lastName}</div>
              </Title>
            </div>
            <div className='account-infos'>
              {/* <div>Abonné CAOM</div> */}
              {/* <div className='light-emphasis'>|</div> */}
              <div className='first-letter-uppercase light-emphasis'>{t('dashboard.createdAt', { context: user.gender, date: user.createdAt.toLocaleDateString(i18n.language, { day: 'numeric', month: 'long', year: 'numeric'}) })}</div>
            </div>
          </div>
          { personalized_shirt && <>
            <div>
              <div className='personalized-shirt'>
                <img className='img-personalized-shirt' src={personalized_shirt} />
                <div className='circle-aura'></div>
              </div>
              <Button type={ButtonType.Secondary} href={getEshop() + '/tenues-de-match/maillots-officiels.html?utm_source=connect.om.fr&utm_medium=referral&utm_campaign=maillots_eshop_om_2425&utm_term=maillots_eshop_om_2425&utm_content=maillots_eshop_connect_om_2425'} isTargetblank={true}>{t('dashboard.favorite_player.shirt_button')}</Button>
            </div>
          </>}
        </div>
        <svg className='background' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon fill="#E3F2FA" points="0,100 100,0 100,100"/>
        </svg>
      </div>

      <div className='dashboard-grid'>
        {/* Informative static block */}
        <div>{!!user && !atLeastOneActivComm ? <>
          <div className='info-block'>
            <div className='header'>
              <Icon type={IconType.Bell}></Icon>
              <div className='title'>{t('dashboard.information_block.title')}</div>
            </div>
            <div className='content'>
              <span>{t('dashboard.information_block.message')}</span>
              <Button type={ButtonType.Link} href='/account/preferences'>{t('dashboard.information_block.action')}</Button>
            </div>
          </div>
        </>:<>
          <div className='info-block'>
            <div className='header'>
              <Icon type={IconType.Bell}></Icon>
              <div className='title'>{t('dashboard.information_block.title')}</div>
            </div>
            <div className='content'>
              <span>{t('dashboard.information_block.message_alerts')}</span>
              <Button type={ButtonType.Link} href='/account/alerts'>{t('dashboard.information_block.action_alerts')}</Button>
            </div>
          </div>
        </>}
        </div>

        {/* Next game block */}
        {next_game ? <>
        <div className='block'>
          <div className='title'>{t('dashboard.next_game.title')}</div>
          <div className='card next-game-block'>
            <div className="date light-background">
              <div className='day'>{new Date(next_game.date.toString().replace('Z', 'T')).getDate()}</div>
              <div className='month'>{new Date(next_game.date.toString().replace('Z', 'T')).toLocaleString(i18n.language, { month: 'short' })}</div>
            </div>
            {next_game.node && <div className="match">
              <div className='home-away'>
                {next_game.node.home && <div className="home">
                  <img src={getOMFR() + next_game.node.home.logo} />
                  {next_game.node.home.shortName ? <>
                    <div>{next_game.node.home.shortName}</div>
                  </>:<>
                  <div>{next_game.node.home.name}</div>
                  </>}
                </div>}
                <div>/</div>
                <div className="away">
                  {next_game.node.away.shortName ? <>
                    <div>{next_game.node.away.shortName}</div>
                  </>:<>
                  <div>{next_game.node.away.name}</div>
                  </>}
                  <img src={getOMFR() + next_game.node.away.logo} />
                </div>
              </div>
              {next_game.node.ticket.link && <Button type={ButtonType.Link} href={next_game.node.ticket.link}>{t('dashboard.next_game.button')}</Button>}
            </div>}
          </div>
        </div>
        </> : <>
          <div className='api-error'>{t('dashboard.next_game.error')}</div>
        </>}

        {/* Peuple Bleu&Blanc block */}
        <div className='block'>
          {user.membership ? <>
            {user.membership.type === Membership_Type.om_legendes ? <>
              <div className='title'>{t('dashboard.pbb.title_legend')}</div>
            </> : <>
              <div className='title'>{t('dashboard.pbb.title_member')}</div>
            </>}
          </> : <>
            <div className='title'>{t('dashboard.pbb.title_not_member')}</div>
          </>}
          <div className={user.membership ? (user.membership.type === Membership_Type.om_legendes ? 'pbb-block legend' : 'pbb-block member') : 'pbb-block card'}>
            {user.membership && user.membership.type !== Membership_Type.om_legendes && <div className='pbb-motif'></div>}
            <div className='content'>
              <div className='content-left'>
                {user.membership ? <>
                  <div>
                    {user.membership.type === Membership_Type.om_legendes ? <>
                      <div className='header'>{t('dashboard.pbb.header_legend')}</div>
                    </> : <>
                      <div className='header'>{t('dashboard.pbb.header_member')}</div>
                    </>}
                    <div className='pbb-id'>{t('dashboard.pbb.member_id', { id: user.membership.id})}</div>
                  </div>
                  <Button type={ButtonType.Primary} href='/account/member' className='see-pbb-button'>{t('dashboard.pbb.action_member')}</Button>
                </> : <>
                  <div className='header'>{t('dashboard.pbb.header_not_member')}</div>
                  <Button className='not-member' type={ButtonType.Link} href='https://membres.om.fr' isTargetblank={true}>{t('dashboard.pbb.action_not_member')}</Button>
                </>}
              </div>
              <img className='logo' src={user.membership ? logo_pbb_light : logo_pbb_dark} />
            </div>
          </div>
        </div>

        {/* Shortcuts block */}
        <div className='block'>
          <div className='title'>{t('dashboard.shortcuts.title')}</div>
          <div className='shortcuts'>
            <Shortcut href={getTicketing()} icon={IconType.Tickets} isTargetblank={true}>{t('dashboard.shortcuts.ticket')}</Shortcut>
            <Shortcut href={getEshop()} icon={IconType.Shop} isTargetblank={true}>{t('dashboard.shortcuts.shop')}</Shortcut>
            <Shortcut href={`${getOMFR()}/fr/videos`} icon={IconType.Play} isTargetblank={true}>{t('dashboard.shortcuts.video')}</Shortcut>
          </div>
            
        </div>

        {/* Favorite Player block */}
        <div className='block'>
          <div className='title'>{t('dashboard.favorite_player.title')}</div>
          <div className='card player-block'>
            {favorite_player ? <>
              {favorite_player.listMedia &&
                <div className='background-player-picture'>
                  <img src={getOMFR() + favorite_player.listMedia} alt='' className='player' />
                </div>
              }
              <div className='desktop player-infos'>
                <div className='player-name'>{ favorite_player.firstName } { favorite_player.lastName }</div>
                <div className="player-position">
                  <div>{ t('dashboard.favorite_player.' + favorite_player.opta_position) } </div>
                  <div>-</div>
                  <div>{t('dashboard.favorite_player.number', { number: favorite_player.number })}</div>
                </div>
                <Button isTargetblank={true} href={getEshop() + '/tenues-de-match/vestiaire-des-joueurs.html?utm_source=connect.om.fr&utm_medium=referral&utm_campaign=maillots_eshop_om_2425&utm_term=maillots_eshop_om_2425&utm_content=maillots_eshop_connect_om_2425'} type={ButtonType.Link}>{t('dashboard.favorite_player.shirt_button')}</Button>
              </div>
              <div className='bloc-player-stats'>
                <div className='mobile player-name'>{ favorite_player.firstName } { favorite_player.lastName }</div>
                {favorite_player.stats_connect && <>
                  <div className='player-stats'>
                    <div className="stat">
                      { favorite_player.stats_connect.find((item: any) => item.played) ? <>
                        <div className='number'>{(favorite_player.stats_connect.find((item: any) => item.played)).played}</div>
                        <div className='type'>{t('dashboard.favorite_player.played', { count: (favorite_player.stats_connect.find((item: any) => item.played)).played })}</div>
                      </>: <>
                        <div className='number'>0</div>
                        <div className='type'>{t('dashboard.favorite_player.played', { count: 0 })}</div>
                      </>}
                    </div>
                    <div className="stat">
                      { favorite_player.stats_connect.find((item: any) => item.goals) ? <>
                        <div className='number'>{(favorite_player.stats_connect.find((item: any) => item.goals)).goals}</div>
                        <div className='type'>{t('dashboard.favorite_player.goals', { count: (favorite_player.stats_connect.find((item: any) => item.goals)).goals })}</div>
                      </>: <>
                        <div className='number'>0</div>
                        <div className='type'>{t('dashboard.favorite_player.goals', { count: 0 })}</div>
                      </>}
                    </div>
                    <div className="stat">
                      { favorite_player.stats_connect.find((item: any) => item.yellowCards) ? <>
                        <div className='number'>{(favorite_player.stats_connect.find((item: any) => item.yellowCards)).yellowCards}</div>
                        <div className='type'>{t('dashboard.favorite_player.yellow_cards', { count: (favorite_player.stats_connect.find((item: any) => item.yellowCards)).yellowCards })}</div>
                      </>: <>
                        <div className='number'>0</div>
                        <div className='type'>{t('dashboard.favorite_player.yellow_cards', { count: 0 })}</div>
                      </>}
                    </div>
                    <div className="stat">
                      { favorite_player.stats_connect.find((item: any) => item.redCards) ? <>
                        <div className='number'>{(favorite_player.stats_connect.find((item: any) => item.redCards)).redCards}</div>
                        <div className='type'>{t('dashboard.favorite_player.red_cards', { count: (favorite_player.stats_connect.find((item: any) => item.redCards)).redCards })}</div>
                      </>: <>
                        <div className='number'>0</div>
                        <div className='type'>{t('dashboard.favorite_player.red_cards', { count: 0 })}</div>
                      </>}
                    </div>
                  </div>
                </>}
                <div className='mobile'><Button isTargetblank={true} href={getEshop() + '/tenues-de-match/vestiaire-des-joueurs.html'} type={ButtonType.Link}>{t('dashboard.favorite_player.shirt_button')}</Button></div>
              </div>
            </> : <>
              <div className='background-player-picture'>
                <img src={no_favorite_player} alt='' className='player' />
              </div>
              <div className='no-player-infos'>
                <div>{t('dashboard.favorite_player.no-player-description')}</div>
                <div className='list-download-buttons'>
                  <a href='https://play.google.com/store/apps/details?id=com.c4mprod.om&hl=fr' target='_blank'>
                    <img src={icon_googleplay} />
                    <div>
                      <div>{t('dashboard.favorite_player.download-google-play')}</div>
                      <div className='store'>{t('dashboard.favorite_player.google-play')}</div>
                    </div>
                  </a>
                  <a href='https://apps.apple.com/fr/app/om-officiel/id346179130' target='_blank'>
                    <img src={icon_apple} />
                    <div>
                      <div>{t('dashboard.favorite_player.download-apple')}</div>
                      <div className='store'>{t('dashboard.favorite_player.app-store')}</div>
                    </div>
                  </a>
                </div>
              </div>
            </>}
          </div>
        </div>

        {/* Events block */}
        {user.isUnderEighteen() &&
          <div className='block'>
            <div className='title'>{t('dashboard.partners.title')}</div>
              <PartnerBloc
                link={"https://www.enligne.parionssport.fdj.fr/inscription/parions-sport-enligne/?xtor=AL-1014-%5BPSEL_PARTENARIAT_OM_ACQ_WEB_GENERIC_INSCRIPTION___%5D-%5BOM%5D&campaign=om&dclid=CLv6hqX9mooDFb14QQIdhhUOJw#/"}
                header={<img src={'https://www.om.fr/sites/default/files/2024-03/logo-psel-1l-2024.png'} alt={'Parions Sport'} />}
                children={t('dashboard.partner.parionssport')}
              />
          </div>
        }
      </div>
    </>;
  }
}

Dashboard.contextType = AuthContext;
export default withTranslation()(Dashboard);
